<template>
  <div id="Add">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header bg-color-primary-4 text-white">
            ثبت مقاله جدید
          </div>
          <div class="card-body">
            <form @submit.prevent="addArticle">
              <div class="mb-3">
                <label class="form-label">عنوان مقاله</label>
                <input type="text" class="form-control" v-model="title" />
              </div>
              <div class="mb-3">
                <label class="form-label">توضیحات مقاله</label>
                <textarea
                  class="form-control"
                  rows="2"
                  v-model="description"
                ></textarea>
              </div>
              <div class="mb-3">
                <label class="form-label">متن کامل مقاله</label>
                <textarea
                  class="form-control"
                  rows="8"
                  v-model="content"
                ></textarea>
              </div>
              <div class="text-center">
                <button type="submit" class="btn btn-sm btn-secondary">
                  <span
                    class="d-inline-flex align-middle mdi mdi-content-save me-1"
                  ></span>
                  ثبت مقاله جدید
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Add",
  data() {
    let articles = localStorage.getItem("articles");
    articles = JSON.parse(articles);
    return {
      articles: articles,
      title: "",
      description: "",
      content: "",
    };
  },
  methods: {
    slugify(string) {
      const a =
        "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
      const b =
        "aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
      const p = new RegExp(a.split("").join("|"), "g");

      return (
        string
          .toString()
          .toLowerCase()
          .replace(/\s+/g, "-") // Replace spaces with -
          .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
          .replace(/&/g, "-and-") // Replace & with 'and'
          //.replace(/[^\w-]+/g, "") // Remove all non-word characters
          .replace(/--+/g, "-") // Replace multiple - with single -
          .replace(/^-+/, "") // Trim - from start of text
          .replace(/[~&\\;"',.<>#\s]/g, "") // Replace spaces ~ & \ ; " ' , . < > #
          .replace(/-+$/, "")
      ); // Trim - from end of text
    },
    addArticle() {
      let article = {
        title: this.title.trim(),
        slug: this.slugify(this.title),
        description: this.description.trim(),
        content: this.content.trim(),
      };
      this.articles.push(article);
      let newArticle = JSON.stringify(this.articles);
      localStorage.setItem("articles", newArticle);
      this.$router.push(`/article/${article.slug}`);
    },
  },
};
</script>

<style scoped></style>
